import BumpsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/bumps/BumpsTemplate';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinTexture/Bumps/BumpsTemplate'
};

const createStory = props => () => ({
  components: { BumpsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <bumps-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  comedones: AMOUNT.NO
});
